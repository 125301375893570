import { useState, useEffect, React } from "react";
import "./FotterAll.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { SlArrowUp, SlArrowUpCircle } from "react-icons/sl";

const FotterAll = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
        if (window.scrollY > 400) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    });
}, []);
const goToTop = () => {
  window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
};
  const handleIconClick = (url) => {
    window.open(url, '_blank'); // Open URL in a new tab
  };


  const openGetDirection = () => {const urlToNavigate = "https://www.google.com/maps/place/18%C2%B057'27.5%22N+72%C2%B050'51.3%22E/@18.9576389,72.8475833,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.9576389!4d72.8475833?hl=en-IN&entry=ttu";window.location.href = urlToNavigate;};
  const openGetSecondAdress = () => {const urlToNavigate = "https://www.google.com/maps/place/18%C2%B048'19.9%22N+72%C2%B052'52.9%22E/@18.8055278,72.8813611,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.8055278!4d72.8813611?hl=en-IN&entry=ttu";window.location.href = urlToNavigate;};

  return (
    <div className="footer_Clas">
      <div className="footer_Parent_Div">
        <div className="footerLogo">
          <img src="/assets/img/footer/M2M_logo.png" alt="logo" />
        </div>
        <div className="footer_mum_Terminal">
          <h1>M2M Mumbai Terminal</h1>
          <p>
          Ferry Wharf, Bhaucha <br /> Dhakka Mumbai <br /> Port, Mumbai - 400010, MH 
          </p>
          <div onClick={openGetDirection} style={{cursor:'pointer'}}>
            <h5>Get Direction</h5>
          </div>
        </div>
        <div className="footer_man_Terminal">
          <h1>M2M Mandwa Terminal</h1>
          <p>
          Mandwa, Ropax Service <br /> Jetty, Mandwa Jetty <br /> Road, Mandwa - 402201, MH 
          </p>
          <div onClick={openGetSecondAdress} style={{cursor:'pointer'}}>
            <h5>Get Direction</h5>
          </div>
        </div>
        <div className="MoreLinks">
          <div className="Morelinks_Heading">
            <h1>More Links</h1>
          </div>
          <div className="Morelinks_Text">
           <Link to={"/about-us"}className="link-without-underline">
              <p>About M2M Ferries</p>
            </Link>
            <Link to={"/travel-guide"}className="link-without-underline">
              <p>Travel Guide</p>
            </Link>
            <Link to={"/faq"}className="link-without-underline">
              <p> FAQ</p>
            </Link>
            <Link to={"/m2m-1"}className="link-without-underline">
              <p> Discover Mandwa Port</p>
            </Link>
            <Link to={"/terms-conditions"}className="link-without-underline">
              <p> Terms & Conditions</p>
            </Link>
           

          </div>
        </div>
        <div className="ContactUSOn">
          <div className="contatct_Lastsec">
            <div className="ContactUSOn_heading">
              <h1>Contact Us</h1>
            </div>

            <div className="ContactUSOn_imgand_number">
              <div className="number_contact">
                <img src="/assets/img/footer/PhonLogo.png" alt="phonenumber" />
                <p>+ 91 8291902662</p>
              </div>
              <div className="email_contact">
                <img src="/assets/img/footer/mailLogo.png" alt="Email" />
                <p>support@m2mferries.com</p>
              </div>
            </div>
          </div>
          <div className="socailMediaButoons">
            <h1>Follow Us On</h1>
            <div className="iconsSocailmedia">
              <img src="/assets/img/footer/Facebook.png" alt="Facebook" onClick={() => handleIconClick('https://www.facebook.com/M2MFerries/')}/>
              <img src="/assets/img/footer/Tweet.png" alt="Tweet" onClick={() => handleIconClick('https://x.com/M2M_Ferries')}/>
              <img src="/assets/img/footer/insta.png" alt="Instagram" onClick={() => handleIconClick('https://www.instagram.com/m2mferries/')}/>
              <img src="/assets/img/footer/Link.svg" alt="LinkedIn" onClick={() => handleIconClick('https://in.linkedin.com/company/m2mferries?trk=public_profile_topcard-current-company')}/>
            </div>
          </div>
        </div>
      </div>
    {/* <img src="/assets/img/footer/Facebook.png" onClick={goToTop} className="icon-position icon-style" title="go to top" alt="go to top" /> */}
      <div className="top-to-btm">
        {" "}
        {showTopBtn && (
        <div onClick={goToTop}>
          <SlArrowUp title="Back to Top" className="icon-position icon-style backTopButton"/>
          {/* <div id="onhoverbtn">BACK TO TOP</div>  */}
        </div>
        )}{" "}
    </div>
  </div>
  
  );
};

export default FotterAll;
