import React from 'react'
import "./BookOnGo.css";

const BookOnGo = () => {
    const openLinkApp = () => {const urlToNavigate = "https://bit.ly/m2mappdownload";window.location.href = urlToNavigate;};
  return (
        <div className="BookOnGo_ScannerContiner">
            <div className="BookOnGo_Complogo">
                <div className="BookOnGo_grid">
                    <div className="BookOnGo_CompHeading">
                        <h1>Book on the Go</h1>
                        <div className="BookOnGo_seven">
                        <div className="section section-1_BookOnGo"></div>
                        <div className="section section-2_BookOnGo"></div>
                        <div className="section section-3_BookOnGo"></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="BookOnGo_Scanner">
                <div className="BookOnGo_Scanner_DivOne">    
                    <div className="BookOnGo_Scanner_DivOne_Heading">
                    <h1>Download<br></br>The M2M Ferries App</h1>
                    </div>
                    <div className="BookOnGo_Scanner_DivOne_QRcode">
                        <img src="/assets/img/Scanner/Qr_Code.png" alt="Qr_code" />
                        <p>Scan Now</p>
                    </div>
                    <div className="BookOnGo_Scanner_DivOne_stores" style={{cursor:'pointer'}}>
                    <img src="/assets/img/Scanner/PlayStore.png"alt="play store"onClick={openLinkApp}/>
                    <img src="/assets/img/Scanner/AppleStore.png"alt="apple store"onClick={openLinkApp}/>
                    </div>
                </div>
                <div className="BookOnGo_Scanner_DivTwo">
                    <img src="/assets/img/Scanner/GroupImg.png" alt="mobile_photho" />
                </div>
            </div>

        </div>
  )
}

export default BookOnGo